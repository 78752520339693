.container {
  width: 100%;
  padding: 10px 0px 0px 20px;
  margin-left: 20px;
  margin-top: 30px;
}

.sub-container {
  width: 100%;
  padding: 10px 0px 0px 20px;
  margin-left: 20px;
  margin-top: opx;
}


.upload-container {
  position: relative;
}

.upload-container input {
  border: 1px solid #92b0b3;
  background: #fff;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  padding: 70px 0 100px 40%;
  text-align: center !important;
  width: 100%;
}

.upload-container input:hover {
  background: #fff;
}

.upload-container:before {
  position: absolute;
  bottom: 50px;
  left: 38%;
  content: " (or) Drag and Drop files here. ";
  color: #3f8188;
  font-weight: 900;
}

.container {
  width: 100%;
  padding: 20px 0px 0px 20px;
}

.textField {
  width: 100%;
}

.typography {
  display: flex;
  align-items: center;
}

.customSubheader {
  color: #f09064 !important;
}

.customTitle {
  color: #0767db !important;
}

.list-item {
  flex: 0 1 auto !important;
  -webkit-flex: 0 1 auto !important;
  flex-grow: 0 !important;
  cursor: pointer;
}

.bold-cell {
  font-weight: 600 !important;
  font-size: 16px !important;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #20b2aa;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  border-radius: 3px;
  border: none;
  transition: all ease 0.2s;
  cursor: pointer;
}

.submit-btn:active {
  /* scale: 0.9; */
  opacity: 0.8;
}

.cancle-button {
  padding: 10px 20px;
  margin: 0px 5px;
  background-color: #f09064;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  border-radius: 3px;
  border: none;
  transition: all ease 0.2s;
  cursor: pointer;
}

.cancle-button:active {
  /* scale: 0.9; */
  opacity: 0.8;
}

/* add button start */
.btn-container {
  display: flex;
  justify-content: flex-end;
}

.btn-container .create a {
  text-decoration: none;
  color: #fff;
}

.btn-container .create a,
.excle-btn button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.2rem;
  padding-inline: 1.2rem;
  background-color: rgb(0 107 179);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  border: none;
  gap: 10px;
  font-weight: 500;
  outline: none;
  overflow: hidden;
  font-size: 18px;
}

.excle-btn button {
  background-color: seagreen;
  padding: 10px;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.btn-container .create a:hover,
.excle-btn button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.btn-container.create a:hover .icon,
.excle-btn button:hover .icon {
  transform: translate(4px);
}

.btn-container .create a:hover::before,
.excle-btn button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.btn-container .create a::before,
.excle-btn button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

/* add button end */
