@import url(https://fonts.googleapis.com/css?family=Roboto:400,500|Sarabun:400,600,700&amp;display=swap);


.arrow-down {
  display: inline-block;
}
.dashboard-container {
  overflow-x: hidden; /* Prevent internal overflow */
  height: 100vh; /* Fit content within the viewport height */
  box-sizing: border-box; /* Include padding in height calculation */
}

.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  display: inline-block;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 0.4em;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.mini-stat .mini-stat-img {
  width: 58px;
  height: 58px;
  line-height: 58px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  text-align: center;
}

.mini-stat .mini-stat-img img {
  max-width: 32px;
}

.mini-stat .mini-stat-label {
  position: absolute;
  right: 0;
  top: 18px;
  padding: 2px 10px 2px 32px;
  -webkit-clip-path: polygon(100% 0,
      100% 50%,
      100% 100%,
      0 100%,
      24% 50%,
      6% 0);
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 24% 50%, 6% 0);
}

.mini-stat .APPROVED {
  background-color:#129f5e!important;
}
.mini-stat .HOLD {
  background-color:#ffc107!important;
}
.mini-stat .PENDING {
  background-color:#0dcaf0!important;
}
.mini-stat .REJECTED {
  background-color:#dc3545!important;
}

@media screen and (min-width: 990px) {
  .HideInDesktop {
    display: none;
  }
}

@media screen and (max-width: 990px) {
  .HideInPhone {
    display: none;
  }
}